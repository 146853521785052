import React, { createRef, useEffect, useRef } from "react"
import { Typography, Row, Col, Button } from "antd"
import "./Introduction.less"
import { Player } from "@lottiefiles/react-lottie-player"
import MentroLandingV2 from "../../../assets/mentroLotties/mentroLanding-v2.json"
import MentroLandingHeroV2 from "../../../assets/mentro/mentrolanding-hero-v2.svg"
import PlayStore from "../../../assets/mentro/playStore.svg"
import Globe from "../../../assets/mentro/globe.svg"
import Google from "../../../assets/mentro/google.svg"
import Microsoft from "../../../assets/mentro/microsoft.svg"
import Flipkart from "../../../assets/mentro/flipkart.svg"
import Intuit from "../../../assets/mentro/intuit.svg"
import Amazon from "../../../assets/mentro/amazon.svg"
import DesignDots from "../../../assets/mentro/designDots.svg"
import { logGa } from "../../../services/analytics"
import { useOnScreen } from "../../../web/hooks/useOnScroll"
import { navigate } from "gatsby"

const { Title, Text } = Typography

let mentorsFrom = [
  {
    key: 1,
    companyName: "google",
    companyImg: Google,
  },
  {
    key: 2,
    companyName: "microsoft",
    companyImg: Microsoft,
  },
  {
    key: 3,
    companyName: "amazon",
    companyImg: Amazon,
  },
  {
    key: 4,
    companyName: "flipkart",
    companyImg: Flipkart,
  },
  {
    key: 5,
    companyName: "intuit",
    companyImg: Intuit,
  },
]
export default function Introduction() {
  const lottiePlayer: any = createRef()
  const ref: any = useRef<HTMLDivElement>(null)
  const isOnScreen = useOnScreen(ref, "100px")
  useEffect(() => {
    isOnScreen ? lottiePlayer.current?.play() : lottiePlayer.current?.stop()
  }, [isOnScreen])

  return (
    <div className="mentroIntroParent" ref={ref}>
      <div className="mentroIntroDiv">
        <img
          src={DesignDots}
          alt="Deisgn Dots"
          className="designDots designDots1"
        />
        <img
          src={DesignDots}
          alt="Deisgn Dots"
          className="designDots designDots2"
        />
        <img
          src={DesignDots}
          alt="Deisgn Dots"
          className="designDots designDots3"
        />
        <Row className="mentroIntroRow">
          <Col lg={14} md={12} className="leftCol">
            <Title className="title">Elevate Your Interview Game Today!</Title>
            <Text className="text">
              Ace your tech interviews with Mentro's AI-driven mentor matching.
              With Mentorship at your fingertips, increase your chances of
              landing your dream job by 94%. Join now and unlock your full
              potential!
              <br />
            </Text>
            <div className="btnsDiv">
              <Button
                type="text"
                className="downloadBtn"
                onClick={() => {
                  logGa("playstore_app_button_introduction_section")
                  window.open(process.env.GATSBY_PLAYSTORE_LINK)
                }}
              >
                <img src={PlayStore} alt="PlayStore" />
                Download app
              </Button>
              <Button
                type="text"
                className="webAppBtn"
                onClick={() => {
                  logGa("watch_demo_introduction_section")
                  navigate("/web/")
                }}
              >
                <img src={Globe} alt="globeButton" />
                Visit web app
              </Button>
            </div>
          </Col>
          <Col lg={10} md={12} className="rightCol">
            <Player
              // ref={lottiePlayer}
              autoplay
              // keepLastFrame
              loop={true}
              src={MentroLandingV2}
              className="heroLottie"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <img
              alt="MentroLandingHeroV2"
              src={MentroLandingHeroV2}
              className="heroSvg"
            />
          </Col>
        </Row>
        <div className="mentroFromDiv">
          <Title className="title">Mentors from</Title>
          <Row className="companyDiv">
            {mentorsFrom.map(company => {
              return (
                <div
                  className={`companyCol ${company.companyName}Col`}
                  key={company.key}
                >
                  <img src={company.companyImg} alt={company.companyName} />
                </div>
              )
            })}
          </Row>
        </div>
      </div>
    </div>
  )
}
