import React, { useEffect, useState } from "react"
import { Drawer, Button } from "antd"
import RightMenu from "./RightMenu"
import "./Navbar.less"
import GreenMentroLogo from "../../../assets/mentor/mentroLogo.svg"
import MentroLogo from "../../../assets/mentro/mentroLogo.svg"
import HamburgerMenu from "../../../assets/mentro/hamburgerMenu.svg"
import HamburgerMenuWhite from "../../../assets/mentro/hamburgerMenuWhite.svg"
import clsx from "clsx"
import { logGa } from "../../../services/analytics"
import { Link } from "gatsby"

function Navbar() {
  const [visible, setVisible] = useState(false)
  const [isCrossedIntro, setIsCrossedIntro] = useState(false)
  const [isCrossed, setIsCrossed] = useState(false)

  const checkScroll = () => {
    if (window.pageYOffset <= 20 && window.pageYOffset >= 0) {
      setIsCrossed(false)
      setIsCrossedIntro(false)
    } else if (window.pageYOffset < 810 && window.pageYOffset > 20) {
      setIsCrossedIntro(true)
      setIsCrossed(false)
    } else if (window.pageYOffset >= 810) {
      setIsCrossedIntro(false)
      setIsCrossed(true)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", checkScroll)
    checkScroll()
    return () => {
      window.removeEventListener("scroll", checkScroll)
    }
  }, [])
  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <nav
      className={clsx([
        "mentorMainNavbarParent",
        isCrossed && "bgMentorMainNavbarParent",
        isCrossedIntro && "bgIntroMentorMainNavbarParent",
      ])}
    >
      <div className="mentorMainNavbar">
        <div className="logoDiv">
          <Link to="/">
            {!isCrossed ? (
              <img
                src={GreenMentroLogo}
                alt="Mentro Logo"
                onClick={() => logGa("navbar_logo_white")}
              />
            ) : (
              <img
                src={GreenMentroLogo}
                alt="Mentro Logo"
                onClick={() => logGa("navbar_logo_green")}
              />
            )}
          </Link>
        </div>
        <div>
          <div className="mentorHideRightMenu">
            <RightMenu close={onClose} isCrossed={isCrossed} />
          </div>
          <Button type="primary" onClick={showDrawer} className="drawerBtn">
            {isCrossed ? (
              <img src={HamburgerMenu} alt="menu icon" />
            ) : (
              <img src={HamburgerMenuWhite} alt="menu icon" />
            )}
          </Button>
          <Drawer
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
          >
            <RightMenu close={setVisible} isCrossed={isCrossed} />
          </Drawer>
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {}

export default Navbar
