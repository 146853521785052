import React from "react"
import "./Features.less"
import { Typography } from "antd"
import Feat1 from "./Feat1"
import Feat2 from "./Feat2"
import Feat3 from "./Feat3"

const { Title } = Typography
export default function Features() {
  return (
    <div className="featuresDiv">
      <Title className="title">Upskill Like Never Before</Title>
      <Feat1 />
      <Feat2 />
      <Feat3 />
    </div>
  )
}
