import React from "react"
import { Col, Input, Row, Typography } from "antd"
import "./Footer.less"
import MentroLogo from "../../../assets/mentor/mentroLogo.svg"
import Linkedin from "../../../assets/mentor/linkedin.svg"
import Instagram from "../../../assets/mentor/instagram.svg"
import Telegram from "../../../assets/mentor/telegram.svg"
import Discord from "../../../assets/mentor/discord.svg"
import FooterDesign from "../../../assets/mentor/design.svg"
import Arrow from "../../../assets/svgs/arrow.svg"
import { logGa } from "../../../services/analytics"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

const { Title, Text } = Typography
export default function Footer() {
  return (
    <div className="footerParent" id="footerTag">
      <Row className="footerDiv">
        {useLocation().pathname === "/mentor" && (
          <img src={FooterDesign} alt="footerDesign" className="footerDesign" />
        )}
        <Col md={6} className="leftCol">
          <img src={MentroLogo} alt="Mentro Logo" />
          <Text className="mentroSummary">Dare to be Better</Text>
          <Text className="year">@{new Date().getFullYear()} Mentro</Text>
        </Col>
        <Col md={4} className="linksCol">
          <Title className="title">Company</Title>
          {/* <Text className="text">About Us</Text> */}
          <Text className="text">
            <a
              href="mailto:contact@mentro.tech"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
          </Text>
          {/* <Text className="text">Careers</Text> */}
          {/* <Text className="text">
            <a
              href="/info/refund-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Refund Policy
            </a>
          </Text> */}
          <Text className="text">
            <a
              href="/info/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </Text>
          <Text className="text">
            <a
              href="/info/terms-of-use/"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </Text>
        </Col>
        <Col md={4} className="linksCol">
          <Title className="title">Resources</Title>
          {/* <Text className="text">FAQ</Text> */}
          <Text className="text">
            <a
              href="/courses"
              target="_blank"
              rel="noreferrer"
            >
              Courses
            </a>
          </Text>
          <Text className="text">
            <a
              href="https://blog.mentro.tech"
              target="_blank"
              rel="noreferrer"
            >
              Blog
            </a>
          </Text>
          {/* <Text className="text">Newsletter</Text> */}
        </Col>
        <Col md={10} className="rightCol">
          <Title className="reachUs">Join Us</Title>
          <div className="inputDiv">
            <Arrow className="arrow" onClick={() => navigate("/web/auth")} />
            <Input
              placeholder="Email address"
              onKeyDown={e => {
                if (e.key === "Enter") {
                  navigate("/web/auth")
                }
              }}
            />
          </div>
          <Text className="contactDetails">
            <span>Email: </span>
            <a
              href="mailto:contact@mentro.tech"
              target="_blank"
              rel="noreferrer"
            >
              contact@mentro.tech
            </a>{" "}
            <br />
          </Text>
          <Text className="contactDetails">
            <span>Address: 26, Central Road, Jadavpur</span> <br /> <span>Kolkata - 700032, West Bengal</span>
            {" "}
            <br />
          </Text>
          <Text className="contactDetails">
            <span>Phone: +91 79801 20665/+91 79800 05728</span>
            
            <br />
          </Text>
          <div className="socialHandles">
            <a
              href="/Telegram"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="logos"
                src={Telegram}
                alt="Telegram"
                onClick={() => logGa("footer_telegram")}
              />
            </a>
            <a
              href="/Linkedin"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="logos"
                src={Linkedin}
                alt="Linkedin"
                onClick={() => logGa("footer_linkedin")}
              />
            </a>
            <a
              href="/Discord"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="logos"
                src={Discord}
                alt="Discord"
                onClick={() => logGa("footer_discord")}
              />
            </a>
            <a
              href="/Instagram"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="logos"
                src={Instagram}
                alt="Instagram"
                onClick={() => logGa("footer_instagram")}
              />
            </a>
          </div>
        </Col>
      </Row>
    </div>
  )
}
