import React from "react"
import { Button } from "antd"
import "./RightMenu.less"
import { Link } from "gatsby"
import clsx from "clsx"
import JoinAsMentor from "../../../assets/mentro/joinAsMentor.svg"
import Courses from "../../../assets/mentro/courses.svg"
import Home from "../../../assets/mentro/home.svg"
import { logGa } from "../../../services/analytics"

const RightMenu = ({ close, isCrossed }) => {
  return (
    <div className="mentroRightMenu">
      <Link to="/">
        <Button
          className={clsx([
            "navBtn",
            typeof window !== "undefined" &&
              window &&
              window.location.pathname === "/" &&
              "link-select",
            isCrossed && "bgNavBtn",
          ])}
          type="text"
          onClick={() => {
            logGa("mentro_landing_home_navbar")
            close(false)
          }}
        >
          <img src={Home} alt="home" />
          Home
          <hr />
        </Button>
      </Link>
      <Link to="/mentor">
        <Button
          className={clsx(["navBtn", isCrossed && "bgNavBtn"])}
          type="text"
          onClick={() => {
            logGa("mentro_landing_join_as_mentor_navbar")
            close(false)
          }}
        >
          <img src={JoinAsMentor} alt="join as mentor" />
          Join as mentor
          <hr />
        </Button>
      </Link>
      <Link to="/courses">
        <Button
          className={clsx(["navBtn", isCrossed && "bgNavBtn"])}
          type="text"
          onClick={() => {
            logGa("mentro_landing_courses_navbar")
            close(false)
          }}
        >
          <img src={Courses} alt="Courses" />
          Courses
          <hr />
        </Button>
      </Link>
      <Button
        className={clsx(["downloadBtn", isCrossed && "bgDownloadBtn"])}
        type="text"
        onClick={() => {
          logGa("playstore_app_button_navbar")
          window.open(process.env.GATSBY_PLAYSTORE_LINK)
          close(false)
        }}
      >
        Download app
      </Button>
    </div>
  )
}

export default RightMenu
