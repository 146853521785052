import React, { createRef, useEffect, useRef } from "react"
import { Typography, Row, Col, Button } from "antd"
import "./Feat3.less"
import { Player } from "@lottiefiles/react-lottie-player"
import Feat3Illustraion from "../../../../assets/mentroLotties/feat3.json"
import RightArrow from "../../../../assets/mentro/rightArrow.svg"
import DesignDots from "../../../../assets/mentro/designDots.svg"
import { logGa } from "../../../../services/analytics"
import { useOnScreen } from "../../../../web/hooks/useOnScroll"
import { navigate } from "gatsby"

const { Title, Text } = Typography

export default function Feat3() {
  const lottiePlayer: any = createRef()
  const ref = useRef<HTMLDivElement>(null)
  const isOnScreen = useOnScreen(ref, "100px")
  useEffect(() => {
    isOnScreen ? lottiePlayer.current?.play() : lottiePlayer.current?.stop()
  }, [isOnScreen])
  return (
    <Row className="feat3Div" ref={ref}>
      <img
        src={DesignDots}
        alt="Deisgn Dots"
        className="designDots designDots1"
      />
      <img
        src={DesignDots}
        alt="Deisgn Dots"
        className="designDots designDots2"
      />
      <Col lg={12} md={12} className="leftCol">
        <Title className="title">Grab Opportunities of your dreams</Title>
        <Text className="text">
          Stay updated with all the latest and best suited opportunities for you
          from the world's leading Tech Giants & Startups
          <br />
        </Text>
        <div className="btnsDiv">
          <Button
            className="moreDetailsBtn"
            onClick={() => {
              logGa("playstore_app_button_opportunities_feature")
              navigate("/web/jobs")
            }}
          >
            More details
            <img src={RightArrow} alt="RightArrow" />
          </Button>
        </div>
      </Col>
      <Col lg={12} md={12} className="rightCol">
        <Player src={Feat3Illustraion} ref={lottiePlayer} keepLastFrame />
      </Col>
    </Row>
  )
}
