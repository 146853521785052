import React from "react"
import "./Functionality.less"
import { Typography, Row, Col } from "antd"
import FunctionalityLanding from "../../../assets/mentro/functionalityLanding.png"
import StartSession from "../../../assets/mentro/startSession.svg"
import TypeOfSession from "../../../assets/mentro/typeOfSession.svg"
import OnlineSession from "../../../assets/mentro/onlineSession.svg"
import ChatWithMentor from "../../../assets/mentro/chatWithMentor.svg"
import BlueArrow from "../../../assets/mentro/blueArrow.svg"
import PinkArrow from "../../../assets/mentro/pinkArrow.svg"
import GreenArrow from "../../../assets/mentro/greenArrow.svg"

const { Title, Text } = Typography

export default function Functionality() {
  return (
    <div className="functionalityParent">
      <div className="functionalityDiv">
        <div className="topDiv">
          <Title className="title">How does it work?</Title>
          <Text className="text">Not just another "Social" media</Text>
          <img
            src={FunctionalityLanding}
            alt="functionalityLanding"
            className="functionalityLandingImg"
          />
        </div>
        <Row className="sessionRow">
          <Col className="sessionCol startSessionCol">
            <img src={StartSession} alt="typeOfSession" />
            <Text className="sessionText">
              Discover <br />
              Creators
            </Text>
          </Col>
          <img
            src={GreenArrow}
            alt="greenArrow"
            className="greenArrow midArrow"
          />
          <Col className="sessionCol typeOfSessionCol">
            <img src={TypeOfSession} alt="startSession" />
            <Text className="sessionText">
              Upskill and <br />
              Learn
            </Text>
          </Col>
          <img src={BlueArrow} alt="blueArrow" className="blueArrow midArrow" />
          <Col className="sessionCol chatWithMentorCol">
            <img src={ChatWithMentor} alt="chatWithMentor" />
            <Text className="sessionText">
              Discover & <br />
              interact
            </Text>
          </Col>
          <img src={PinkArrow} alt="pinkArrow" className="pinkArrow midArrow" />
          <Col className="sessionCol onlineSessionCol">
            <img src={OnlineSession} alt="onlineSession" />
            <Text className="sessionText">
              Resolve <br />
              Doubts
            </Text>
          </Col>
        </Row>
        {/* <Text className="textContent">
          Start learning or exploring any domain - Learn from the fully curated
          content from expert roadmaps across levels of Basic to Advanced for
          FREE; Got stuck? Need help? Placements coming? Interview Scheduled?
          Don't worry, start a session with the best in the business - our
          passionate and top-notch Mentors are here for you to guide you in the
          most accessible, easy and quick way.
        </Text> */}
      </div>
    </div>
  )
}
