import React, { useEffect } from "react"
import "../theme/index.less"
import Container from "../components/MentroLanding/Container"
import Seo from "../components/seo"
import Introduction from "../components/MentroLanding/Introduction"
import Details from "../components/MentroLanding/Details"
import Features from "../components/MentroLanding/Features"
import Functionality from "../components/MentroLanding/Functionality"
import AiDriven from "../components/MentroLanding/AiDriven"
import Testimonials from "../components/MentroLanding/Testimonials"
import Team from "../components/MentroLanding/Team/Team"

const MentroLandingPage = () => {
  // useEffect(() => {
  //   //TAWK.to
  //   var Tawk_API = Tawk_API || {}

  //   var s1: any = document.createElement("script")
  //   var s0: any = document.getElementsByTagName("script")[0]
  //   s1.async = true
  //   s1.src = "https://embed.tawk.to/622f1fada34c2456412af169/1fu417g0k"
  //   s1.charset = "UTF-8"
  //   s1.setAttribute("crossorigin", "*")
  //   s0.parentNode.insertBefore(s1, s0)
  // }, [])

  return (
    <Container>
      <Seo title="Mentro: Learn with Mentors" />
      <Introduction />
      <Details />
      <Features />
      <Team />
      <Functionality />
      <Testimonials />
      <AiDriven />
    </Container>
  )
}

export default MentroLandingPage
