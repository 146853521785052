import React from "react"
import { Typography, Row, Col, Button } from "antd"
import "./Details.less"
import WhatMentro from "../../../assets/mentro/whatMentro.png"
import GreenGlobe from "../../../assets/mentro/greenGlobe.svg"
import RightArrow from "../../../assets/mentro/rightArrow.svg"
import { logGa } from "../../../services/analytics"
import { navigate } from "gatsby"

const { Title, Text } = Typography

export default function Details() {
  return (
    <div className="detailsParent">
      <Row className="detailsDiv">
        <Col lg={12} md={12} className="leftCol">
          <img src={WhatMentro} alt="Landing WhatMentro" />
        </Col>
        <Col lg={12} md={12} className="rightCol">
          <Title className="title">Find your perfect mentor</Title>
          <img
            src={WhatMentro}
            alt="Landing WhatMentro"
            className="whatMentroImg"
          />
          <Text className="text">
            Your ideal mentor is just a click away. Choose from a diverse
            network of mentors based on their expertise, ratings, and your
            career aspirations. Get started today and experience the power of
            mentorship.
          </Text>
          <div className="detailsBtnsDiv">
            <Button
              type="text"
              className="moreDetailsBtn"
              onClick={() => {
                logGa("more_details_button_details_section")
                window.open(process.env.GATSBY_PLAYSTORE_LINK)
              }}
            >
              More details
              <img src={RightArrow} alt="RightArrow" />
            </Button>
            <Button
              type="text"
              className="greenGlobeBtn"
              onClick={() => {
                logGa("watch_demo_details_section")
                navigate("/web/")
              }}
            >
              <img src={GreenGlobe} alt="globeButton" />
              Visit web app
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}
