import React, { createRef, useEffect, useRef } from "react"
import { Typography, Row, Col, Button } from "antd"
import "./Feat1.less"
import { Player } from "@lottiefiles/react-lottie-player"
import Feat1Illustraion from "../../../../assets/mentroLotties/feat1.json"
import PlayStore from "../../../../assets/mentro/playStore.svg"
import DesignDots from "../../../../assets/mentro/designDots.svg"
import { logGa } from "../../../../services/analytics"
import { useOnScreen } from "../../../../web/hooks/useOnScroll"

const { Title, Text } = Typography

export default function Feat1() {
  const lottiePlayer: any = createRef()
  const ref = useRef<HTMLDivElement>(null)
  const isOnScreen = useOnScreen(ref, "100px")
  useEffect(() => {
    isOnScreen ? lottiePlayer.current?.play() : lottiePlayer.current?.stop()
  }, [isOnScreen])

  return (
    <Row className="feat1Div" ref={ref}>
      <img
        src={DesignDots}
        alt="Deisgn Dots"
        className="designDots designDots1"
      />
      <img
        src={DesignDots}
        alt="Deisgn Dots"
        className="designDots designDots2"
      />
      <Col lg={12} md={12} className="leftCol">
        <Title className="title">
          Struggling to Crack Job Interviews? Mentro Can Help!
        </Title>
        <Text className="text">
          Discover Top Mentors, Industry Professionals and Domain Experts. Join
          a Creator, Book 1-on-1 sessions, Enrol in your favourite workshops,
          CBCs, and more! Fix your resume, Prepare for Interviews, Build
          Projects and be the most impressive candidate in the pool. Get
          upskilled to grab the next big opportunity ahead of you ✅
          <br />
        </Text>
        <div className="btnsDiv">
          <Button
            className="downloadBtn"
            onClick={() => {
              logGa("playstore_app_button_session_feature")
              window.open(process.env.GATSBY_PLAYSTORE_LINK)
            }}
          >
            <img src={PlayStore} alt="PlayStore" />
            Download app
          </Button>
        </div>
      </Col>
      <Col lg={12} md={12} className="rightCol">
        <Player ref={lottiePlayer} keepLastFrame src={Feat1Illustraion} />
      </Col>
    </Row>
  )
}
