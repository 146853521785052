import React from "react"
import "./Team.less"
import { Typography, Carousel, Grid } from "antd"
import RightArrow from "src/assets/svgs/right.svg"
import LeftArrow from "src/assets/svgs/left.svg"

const teamData = [
  {
    id: 1,
    name: "Aanshul Sadaria",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/website-assets%2Fsadaria.jpeg?alt=media&token=f5119b47-c7a1-41eb-a479-3bf3640de989",
    company: "SWE II @Google | Ex Adobe",
  },
  {
    id: 2,
    name: "Shivam Goyal",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2F6oZ6xzMZB2bQXxezvuvHuWqDD9l2%2Fprofile.jpg?alt=media&token=11bdc4bc-ece4-4ff9-a542-bea13b3a0c00",
    company: "Software Engineer @Navi",
  },
  {
    id: 3,
    name: "Anubhav Sinha",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2FAzvpxRQyHXV7yexwDRZtaTGosr12%2Fprofile.jpg?alt=media&token=2a59d38f-b3dc-4e86-9460-bea0a6929435",
    company: "SDE @Amazon",
  },
  {
    id: 4,
    name: "Jaideep Prasad",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2FEKQ2A2OH7RaKAHPJGvIxnuDT5rx1%2Fprofile.jpg?alt=media&token=23688e07-11a4-47dc-9606-e196f5197c21",
    company: "Co-Founder @Mentro | Ex-Jio",
  },
  {
    id: 5,
    name: "Aditya Gupta",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2FIcHUWvI4hZexFnqTSJF5baMvpZf1%2Fprofile.jpg?alt=media&token=a47023a7-f27f-4385-8159-6b6c04d01f4b",
    company: "SRE @Microsoft, Dublin",
  },
  {
    id: 6,
    name: "Mukul Kumar",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2Fb5mB7TZ9EJUCW48A0f63UL8thWa2%2Fprofile.jpg?alt=media&token=ddd06104-803c-4899-a619-cbc113cb4751",
    company: "SDE @Amazon",
  },
]

const { Title } = Typography
const { useBreakpoint } = Grid

export default function Team() {
  const { xxl } = useBreakpoint()
  return (
    <div className="mentroTeamParent">
      <div className="mentroTeamDiv">
        <Title className="title">Discover your Mentor</Title>

        <Carousel
          arrows={xxl}
          className="mentor-card"
          infinite
          autoplaySpeed={2000}
          slidesToShow={3}
          autoplay={true}
          nextArrow={<RightArrow className="right-arrow" id="right-arrow" />}
          prevArrow={<LeftArrow className="left-arrow" id="left-arrow" />}
        >
          {teamData.map(({ name, image, company }) => (
            <div className="mentor-box" key={name}>
              <div className="mentor-image-container">
                <img src={image} alt="Mentor1" className="mentor-image" />
              </div>
              <Title className="nameTitle">{name}</Title>
              <Title className="companyTitle">{company}</Title>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
