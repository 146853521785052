import React, { useState } from "react"
import "./Testimonials.less"
import { Typography } from "antd"
import DoubleQuotesIcon from "../../../assets/mentro/double-quotes.svg"

import { userData } from "./testimonialData.js"

const { Title, Text } = Typography

const TestimonialCard = ({
  testimonialData,
}: {
  testimonialData: { id: string; avatar: string; name: string; quote: string }
}) => {
  return (
    <div className="card">
      <div className="testimonialsHeader">
        <div className="doubleQuotesIconWrapper">
          <img
            src={DoubleQuotesIcon}
            alt="DoubleQuotesIcon"
            className="doubleQuotesIcon"
            // onClick={prevUser}
          />
          {/* <DoubleQuotesIcon /> */}
        </div>
        <Text style={{ color: "#ffffff" }}>{testimonialData.quote}</Text>
      </div>
      <div className="testimonialUserWrapper">
        <div className="userAvatarWrapper">
          <img
            loading="lazy"
            alt={testimonialData.name}
            src={testimonialData.avatar}
            width="40px"
            height="40px"
          />
        </div>
        <Text style={{ color: "#222222", marginBottom: "4px" }}>
          {testimonialData.name}
        </Text>
      </div>
    </div>
  )
}

export default function Testimonials() {
  const [expandTestimonialGrid, setExpandTestimonialGrid] = useState(false)

  const handleShowMoreClick = () => {
    setExpandTestimonialGrid(true)
  }

  const handleShowLessClick = () => {
    setExpandTestimonialGrid(false)
  }

  return (
    <div className="testimonialsDiv">
      <div className="topDiv">
        <Text className="text">Testimonials</Text>
        <Title className="title">Our Happy Users</Title>
      </div>
      <div
        className="testimonialsContainer"
        style={{
          paddingBottom: expandTestimonialGrid ? "100px" : "0px",
        }}
      >
        <div
          className={`gridContainer ${
            expandTestimonialGrid && "expand-gridContainer"
          }`}
        >
          <div>
            {userData
              .slice(0, 2)
              .map(
                (testimonialData: {
                  id: string
                  avatar: string
                  name: string
                  quote: string
                }) => (
                  <TestimonialCard
                    testimonialData={testimonialData}
                    key={testimonialData.name}
                  />
                )
              )}
          </div>
          <div>
            {userData
              .slice(2, 4)
              .map(
                (testimonialData: {
                  id: string
                  avatar: string
                  name: string
                  quote: string
                }) => (
                  <TestimonialCard
                    testimonialData={testimonialData}
                    key={testimonialData.name}
                  />
                )
              )}
          </div>
          <div>
            {userData
              .slice(4)
              .map(
                (testimonialData: {
                  id: string
                  avatar: string
                  name: string
                  quote: string
                }) => (
                  <TestimonialCard
                    testimonialData={testimonialData}
                    key={testimonialData.name}
                  />
                )
              )}
          </div>
        </div>
        <div>
          <div
            className="buttonWrapper"
            style={{
              background: expandTestimonialGrid
                ? "transparent"
                : "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 56.86%)",
            }}
          >
            <button
              onClick={
                !expandTestimonialGrid
                  ? handleShowMoreClick
                  : handleShowLessClick
              }
            >
              {!expandTestimonialGrid
                ? "Show More Testimonials"
                : "Show Less Testimonials"}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
