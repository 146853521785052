import React, { useState } from "react"
import { Typography, Row, Col, Button } from "antd"
import "./AiDriven.less"
import AiDrivenIllustraion from "../../../assets/mentro/aiDrivenIllustration.svg"
import PlayStore from "../../../assets/mentro/playStore.svg"

const { Title, Text } = Typography

export default function AiDriven() {
  return (
    <div className="aiDrivenParent">
      <Row className="aiDrivenDiv">
        <Col lg={12} md={12} className="leftCol">
          <div className="top">
            <Title className="title">AI-driven Mentor Matching</Title>
            {/* <AiDrivenIllustraion /> */}
            <img
              src={AiDrivenIllustraion}
              alt="AiDriven"
              className="aiDrivenIllImg"
            />
            <Text className="text">Coming Soon</Text>
            <div className="btnsDiv">
              <Button
                className="downloadBtn"
                onClick={() => {
                  window.open(process.env.GATSBY_PLAYSTORE_LINK)
                }}
              >
                <img src={PlayStore} alt="PlayStore" />
                Download app
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={12} md={12} className="rightCol">
          <img src={AiDrivenIllustraion} alt="AiDriven 1" />
        </Col>
      </Row>
    </div>
  )
}
