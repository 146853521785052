import React from "react"
import img_sharleen from "src/assets/images/sharleen.png"
import img_anshul from "src/assets/images/anshulSrivastava.jpg"

export const userData = [
  {
    id: 1,
    name: "Aditya",
    quote:
      "A huge shoutout for creating such a productive platform for all the students to make their career better by providing them the all the latest opportunities under one platform I recently got the internship in GoJek and came to know about the opportunity through Mentro app only 😄",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2FUewT9LrPxAesVvYHJQyeQNhO5Sh1%2Fprofile.jpg?alt=media&token=f2027135-afaa-4fee-a0b2-9f624b5a7165",
  },
  {
    id: 2,
    name: "Sharleen",
    quote: `Mentro is a great initiative to connect to mentors! I came across
           them on LinkedIn and ever since then I have been following them. 
           They make sure to update us with all the opportunities out there. 
           Mentro helps students who don't get proper guidance and who are not 
           aware of the ton of opportunities present. My mentor took out a 
           lot of time to guide me and help me figure things out. 
           Thankful to the Mentro team!`,
    avatar: img_sharleen,
  },
  {
    id: 3,
    name: "Utkarsh",
    quote: `Mentro is a great initiative to connect hustlers with industry
           experts. I get latest updates on all internships and all thanks
           to mentoring session, I was able to restructure my resume neatly
           and get help for interviews from the mock interview sessions`,
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/website-assets%2Futkarsh-user.jpeg?alt=media&token=b963abda-2309-4e95-bbe8-2c5ce3c59459",
  },
  {
    id: 4,
    name: "Ameya",
    quote: `My mentorship session was really helpful. Cleared all my doubts
           one to one and which weren't cleared during any other events or
           youtube live sessions before anywhere. My mentor was really
           helpful and gave me time from their schedule for this. Really
           thankful to Mentro`,
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/website-assets%2FIMG_20200215_131838.jpg?alt=media&token=4ba4d241-d1c6-42cb-bd35-fc557ff627f0",
  },
  {
    id: 5,
    name: "Vidhu",
    quote: `I got to know about the Mentro app from a friend and from that
           I just love it. The discord community is great, you can meet
           various people with similar interests as yours, share doubts,
           coding problems and lots more. The Mentro app has awesome mentors.
           I was glad to meet Jaideep sir and he really guided me back clearing
           all my doubts and showing me a clear path. Kudos to this initiative!`,
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/website-assets%2FIMG_20210813_222636.jpg?alt=media&token=ede856fb-4aaf-4bcc-a765-3fb55712cae9",
  },
  {
    id: 6,
    name: "Anshul Srivastava",
    quote: `I recently started learning DSA with Mentro and the platform helped me a lot in understanding the basic to advance concepts and practice important interview problems of DSA.`,
    avatar: img_anshul,
  },
]
