import React, { createRef, useEffect, useRef } from "react"
import { Typography, Row, Col, Button } from "antd"
import "./Feat2.less"
import { Player } from "@lottiefiles/react-lottie-player"
import Feat2Illustraion from "../../../../assets/mentroLotties/feat2.json"
import RightArrow from "../../../../assets/mentro/rightArrow.svg"
import { logGa } from "../../../../services/analytics"
import { useOnScreen } from "../../../../web/hooks/useOnScroll"
import { navigate } from "gatsby"

const { Title, Text } = Typography

export default function Feat2() {
  const lottiePlayer: any = createRef()
  const ref = useRef<HTMLDivElement>(null)
  const isOnScreen = useOnScreen(ref, "100px")
  useEffect(() => {
    isOnScreen ? lottiePlayer.current?.play() : lottiePlayer.current?.stop()
  }, [isOnScreen])

  return (
    <div className="feat2Parent" ref={ref}>
      <Row className="feat2Div">
        <Col lg={12} md={12} className="leftCol">
          <Player ref={lottiePlayer} keepLastFrame src={Feat2Illustraion} />
        </Col>
        <Col lg={12} md={12} className="rightCol">
          <Title className="title">Learn from the best in the biz</Title>
          <Text className="text">
            Level up your interview game and become a pro with our kickass
            interview prep courses! Designed to equip you with all the tools you
            need to nail that job interview, our courses cover everything from
            dealing with tricky questions to perfecting your storytelling
            skills. Don't just blend in with the competition - stand out and get
            hired with our comprehensive interview preparation courses!
            <br />
          </Text>
          <div className="btnsDiv">
            <Button
              className="moreDetailsBtn"
              onClick={() => {
                logGa("playstore_app_button_resources_feature")
                navigate("/web/")
              }}
            >
              More details
              <img src={RightArrow} alt="RightArrow" />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}
